import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Tabs from "./components/Tabs/Tabs"
import Logs from "./components/Logs"
import InquiriesV2 from "./components/InquiriesV2";

function App() {
    return(
        <div>
            <BrowserRouter>
                <Switch>
                    <Route path={'/inquiries/:userId'} exact={true} component={Tabs}/>
                    <Route path={'/logs/:userId'} exact={true} component={Logs}/>
                    {/*<Route path={'/auditProjects/:userId'} exact={true} component={AuditProjects}/>*/}
                    <Route path={'/tabs/:userId'} component={InquiriesV2}/>
                    {/*<Route path={'/audit'} component={Audit}/>*/}

                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default App;