import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {TextField} from "@material-ui/core";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function LoadURL(props) {
    let userId = props.props.userId;
    let FileName = props.props.name;
    let url = props.props.url;
    let size = props.props.size;
    let extension = "";
    if (url.includes(".mp3") || url.includes(".MP3")){
        extension = "mp3"
    } else if(url.includes(".m4a") || url.includes(".M4A")){
        extension = "m4a"
    }else if(url.includes(".mp4") || url.includes(".MP4")){
        extension = "mp4"
    }else if (url.includes(".jpeg") || url.includes(".JPEG")){
        extension = "jpeg"
    }else if (url.includes(".jpg") || url.includes(".JPG")){
        extension = "jpg"
    }else if (url.includes(".png") || url.includes(".PNG")){
        extension = "png"
    }
    let menu = "Consultas Firebase";
    let reasons = [];
    let userName = "";
    let questionId = "";
    let answeredQuestionId = "";
    let regex = "";
    let validate = "";
    let bit = 10;
    let funcion = "";
    const [nameUser, setNameUser] = useState("");
    const [openReasons, setOpenReasons] = useState(false);
    const [open, setOpen] = useState(false);
    const [idQuestion, setIdQuestion] = useState("");
    const [idAnsweredQuestion, setIdAnsweredQuestion] = useState("");
    const [sizeError, setSizeError] = useState(false);
    const [sizeErrorDescription, setSizeErrorDescription] = useState(false);
    const [reason, setReason] = React.useState(100);
    const [textArea, setTextArea] = React.useState("");
    const [reasonMessage, setReasonMessage] = React.useState(false);
    const [bitInsert, setBitInsert] =  React.useState(false);
    const [bitInsertFalse, setBitInsertFalse] =  React.useState(false);
    const [loadButton, setLoadbutton] = useState(false)
    const [buttonYes, setButtonYes] = useState(false)
    const classes = useStyles();

    const getUserName = () => {
        axios.post(process.env.REACT_APP_API_URL + '/multimedia/surveys/get-user-name', { user_id: userId})
            .then((res) => {
                userName = res.data.response[0].userName;
                setNameUser(res.data.response[0].userName);
            })
            .catch(() => {
                userName = "";
            })
            .finally(() => {
                setOpen(true);
            });
    }

    const getReasons = () => {
        axios.post(process.env.REACT_APP_API_URL + '/multimedia/surveys/get-reasons', {user_id: userId})
            .then((res) => {
                reasons = res.data.response;
            })
            .catch(() => {
                reasons = [];
            })
            .finally(() => {
                setOpenReasons(true);
                setOpen(false);
            });
    }

    const closeSelection = () => {
        setOpen(false);
    }

    const newAsignationId = (e) => {
        questionId = e.target.value;
        setIdQuestion(e.target.value);
    }

    const newAnsweredQuestionId = (e) => {
        answeredQuestionId = e.target.value;
        setIdAnsweredQuestion(e.target.value);
    }

    const readLength = () => {
        if (idAnsweredQuestion.length > 0){
            getReasons();
        }else{
            setSizeError(true);
        }
    }

    const closeSnackBar = () => {
        setSizeError(false);
    }

    const closeSnackBarDescription = () => {
        setSizeErrorDescription(false);
    }

    const closeSnackBarReason = () => {
        setReasonMessage(false);
    }

    const closeLog = () => {
        setButtonYes(false);
        setReason(100);
        setOpenReasons(false);
    }

    const changeReason = (e) => {
        setReason(e.target.value);
    }

    const readLengthDescription = () => {
        if (textArea.length >= 30){
            setButtonYes(true);
            readReason();
        }else{
            setSizeErrorDescription(true);
        }
    }

    const readReason = () => {
        if (reason !== 100){
            insertRegex();
        }else {
            setReasonMessage(true);
        }
    }

    const insertRegex = () => {
        axios.post(process.env.REACT_APP_API_URL + '/multimedia/surveys/insert-regex', {answered_survey_id: parseInt(idAnsweredQuestion), question_id: idQuestion, extension: extension, name: FileName, url: url, size: size, user: nameUser})
            .then((res) => {
                regex = res.data.response;
            })
            .catch(() => {
                regex = 100;
            })
            .finally(() => {
                if (regex === 1){
                    console.log("Insert realizado con éxito");
                    funcion = "Insert Regt Externo";
                    setBit(funcion);
                }else {
                    console.log("Hubo un problema al insertar");
                    setBitInsertFalse(true);
                }
            });
    }

    const setBit = (funcion) => {
        axios.post(process.env.REACT_APP_API_URL + '/multimedia/surveys/insert-bit', {menu: menu, function: funcion, backup: null, current: FileName,
            reason: reason, observations: textArea, user: nameUser, user_modified: nameUser, asignation_id: null, location_id: null, project_id: null,
            task_id: null, answered_survey_id: null, audit_id: null, task_detail_id: null, user_id: userId, repo_multi_id: null, task_location_id: null})
            .then((res) => {
                bit = res.data.response;
            })
            .catch(() => {
                bit = 8;
            })
            .finally(() => {
                if (bit === 1){
                    console.log("Bitacora guardada");
                    setLoadbutton(true);
                    setOpenReasons(false);
                    setBitInsert(true);
                }else{
                    console.log("Bitacora sin guardar");

                }
            });
    }

    const changeTextArea = (e) => {
        setTextArea(e.target.value);
    }

    const closeSnackBarBit = () => {
        setBitInsert(false);
    }

    const closeSnackBarBitFalse = () => {
        setBitInsertFalse(false);
    }

        return (
            <div>
                <Button variant="outlined" onClick={getUserName} disabled={loadButton}>
                    CARGAR
                </Button>

                <Snackbar open={sizeError} autoHideDuration={6000} onClose={closeSnackBar}
                          anchorOrigin={{horizontal: 'center', vertical: 'top'}} fontSize={'xx-large'}>
                    <Alert onClose={closeSnackBar} severity={"error"} variant={"filled"} fontSize={'40px'}>
                        Debe escribir un Id EncuContestada
                    </Alert>
                </Snackbar>
                <Snackbar open={sizeErrorDescription} autoHideDuration={6000} onClose={closeSnackBarDescription}
                          anchorOrigin={{horizontal: 'center', vertical: 'top'}} fontSize={'xx-large'}>
                    <Alert onClose={closeSnackBarDescription} severity={"error"} variant={"filled"} fontSize={'40px'}>
                        El texto debe contener más de 30 caracteres
                    </Alert>
                </Snackbar>
                <Snackbar open={reasonMessage} autoHideDuration={5000} onClose={closeSnackBarReason}
                          anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                    <Alert onClose={closeSnackBarReason} severity="info" variant={"filled"}>
                        Se debe escoger un motivo
                    </Alert>
                </Snackbar>
                <Snackbar open={bitInsert} autoHideDuration={6000} onClose={closeSnackBarBit}
                          anchorOrigin={{horizontal: 'center', vertical: 'top'}} fontSize={'xx-large'}>
                    <Alert onClose={closeSnackBarBit} severity={"success"} variant={"filled"} fontSize={'40px'}>
                        Se finalizó el proceso con éxito
                    </Alert>
                </Snackbar>
                <Snackbar open={bitInsertFalse} autoHideDuration={6000} onClose={closeSnackBarBitFalse}
                          anchorOrigin={{horizontal: 'center', vertical: 'top'}} fontSize={'xx-large'}>
                    <Alert onClose={closeSnackBarBitFalse} severity={"error"} variant={"filled"} fontSize={'40px'}>
                        Hubo un problema al insertar
                    </Alert>
                </Snackbar>

                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={closeSelection}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title"
                                 style={{textAlign: 'center'}}>{"Complete los siguientes campos"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description" style={{textAlign: 'center'}}>
                            Complete los campos con la información deseada
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <TextField placeholder={"Id Pregunta"} onChange={newAsignationId}/>
                        <TextField placeholder={"Id EncuContestada"} onChange={newAnsweredQuestionId}/>
                    </DialogActions>
                    <div style={{textAlign: 'center', alignContent: 'center'}}>
                        <Button onClick={readLength}>Continuar</Button>
                    </div>
                </Dialog>


                <Dialog
                    open={openReasons}
                    onClose={closeLog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"INFORMACIÓN"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Antes de continuar, por favor proporcionar los siguientes datos
                        </DialogContentText>
                        <form style={{textAlign: "center"}}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="demo-dialog-native">Motivo</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={reason}
                                    onChange={changeReason}
                                >
                                    <MenuItem value={1}>Problemas con aplicacion</MenuItem>
                                    <MenuItem value={2}>Problemas con sincronizacion</MenuItem>
                                    <MenuItem value={3}>Problemas con el pax</MenuItem>
                                    <MenuItem value={4}>Error de sistema</MenuItem>
                                    <MenuItem value={5}>Error de usuario</MenuItem>
                                    <MenuItem value={6}>Solicitud de negocios</MenuItem>
                                    <MenuItem value={7}>Solicitud de cliente</MenuItem>
                                    {/*{reasons.map(reason => {*/}
                                    {/*    return(*/}
                                    {/*        <MenuItem key={reason.id} value={reason.id}>{reason.name}</ MenuItem>*/}
                                    {/*    )}*/}
                                    {/*)}*/}
                                </Select>
                            </FormControl>
                        </form>
                        <form
                            style={{
                                textAlign: "center",
                                alignItems: "center",
                                alignContent: "center"
                            }}
                        >
                            <FormControl
                                style={{
                                    textAlign: "center",
                                    alignItems: "center",
                                    alignContent: "center"
                                }}
                            >
                            <textarea
                                style={{width: "100%", heigth: "100%"}}
                                className={"full-space"}
                                placeholder={"Descripcion"}
                                onChange={changeTextArea}
                                value={textArea}
                                minLength="30"
                                maxLength="2000"
                                cols="100"
                                rows="10"
                                required={true}
                            />
                            </FormControl>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeLog} color="primary">
                            NO
                        </Button>
                        <Button onClick={readLengthDescription} color="primary" autoFocus disabled={buttonYes}>
                            SI
                        </Button>
                    </DialogActions>
                </Dialog>


            </div>
        );
}