import React, {useEffect} from "react";
import esMessages from '../es.json'
import firebase from "../components/firebase";
import {IntlProvider, loadMessages, LocalizationProvider} from "@progress/kendo-react-intl";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Input, Snackbar,
    TextField
} from "@material-ui/core";
import {Alert, Autocomplete} from "@material-ui/lab";
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import Back from "./Back";
import {filterBy} from "@progress/kendo-data-query";
import Button from "@progress/kendo-react-buttons/dist/es/Button";
import Linear from "./Loader/CircularLoader";
import LoadURL from "./PopUps/LoadURL";
import axios from "axios";

loadMessages(esMessages, 'es-ES')

let newFormatOption = "";
let newSearchType = "";
let usuarioId = 0;
let ubicacionId = 0;
let name = [];
let trueName = [];
let url = [];
let size = [];
let date = [];
let data = [];
let placeholder = "";
let array = [];
let url1 = "";
let size1 = 0;
let date1 = "";
let validate = [];
let validate1 = 10;

function InquiriesV2(props){

    let userId = atob(props.match.params.userId);
    const [loader, setLoader] = React.useState(false);
    const [message, setMessage] = React.useState(false);
    const [data1, setData1] = React.useState((array));
    const [buttonDis, setButtonDis] = React.useState(false);
    const [inquirieDis, setInquirieDis] = React.useState(true);
    const [errorMessage1, setErrorMessage1] = React.useState(false);
    const [errorMessage2, setErrorMessage2] = React.useState(false);
    const [formatError, setFormatError] = React.useState(false);
    const [userError, setUserError] = React.useState(false);
    const [searchError, setSearchError] = React.useState(false);
    const [textError, setTextError] = React.useState(false);
    const [searchTextError, setSearchTextError] = React.useState(false);
    const [searchTextError1, setSearchTextError1] = React.useState(false);
    const [dateInfo, setDateInfo] = React.useState(false);
    const [userInvalid, setUserInvalid] = React.useState(false);
    const [idUsuario, setIdUsuario] = React.useState(0);
    const [idUbicacion, setIdUbicacion] = React.useState(0);
    const formatOptions = [{format: "Audio"}, {format: "Video"}, {format: "Imagen"}]
    const formatOptions1 = [{search: "Id"}, {search: "Nombre"}]
    const [skip, setSkip] = React.useState(0);
    const [take, setTake] = React.useState(20);
    const [filter, setFilter] = React.useState({
        logic: "or",
        filters: [
            { field: "name", operator: "contains", value: "" },
            { field: "size", operator: "contains", value: "" },
            { field: "url", operator: "contains", value: "" },
            { field: "date", operator: "contains", value: "" }
        ]
    })

    const setNewFormatOption = (e) => {
        newFormatOption = (e.target.innerText);
        console.log(newFormatOption);
    }

    const setNewFormatOption1 = (e) => {
        newSearchType = (e.target.innerText);
        console.log(newSearchType);
        readSearchType(newSearchType);
    }

    const readSearchType = (newSearchType) => {
        if (newSearchType === "Id"){
            placeholder = "Id Ubicacion Tarea";
        }else if (newSearchType === "Nombre"){
            placeholder = "Nombre Archivo"
        }
    }

    const newTextField = (e) => {
        setIdUsuario(e.target.value)
        usuarioId = (e.target.value)
        quantityError(usuarioId);
    }

    const newTextField1 = (e) => {
        setIdUbicacion(e.target.value)
        ubicacionId = (e.target.value)
        quantityError1(ubicacionId);
    }

    const quantityError = (usuarioId) => {
        if (usuarioId.length < 1){
            setErrorMessage1(true);
        }else{
            setErrorMessage1(false);
        }
    }

    const quantityError1 = (ubicacionId) => {
        if (ubicacionId.length < 1){
            setErrorMessage2(true);
        }else{
            setErrorMessage2(false);
        }
    }

    const getUrl = () => {
        setLoader(true);
        if (newFormatOption === "Video") {
            let bucketName = "Videos/";
            let i = 0;
            firebase.storage().ref(`${bucketName}`).child(usuarioId).listAll().then(function (result) {
                if (result.items.length === 0){
                    setTimeout(showMessage, 50);
                }
                result.items.forEach(function (imageRef) {
                    displayMetadata(i, imageRef, bucketName);
                    i++;
                });

            });
        } else if (newFormatOption === "Imagen") {
            let bucketName = "Imagenes/";
            let i = 0;
            firebase.storage().ref(`${bucketName}`).child(usuarioId).listAll().then(function (result) {
                if (result.items.length === 0){
                    setTimeout(showMessage, 50);
                }
                result.items.forEach(function (imageRef) {
                    displayMetadata(i, imageRef, bucketName);
                    i++;
                });
            });
        } else if (newFormatOption === "Audio") {
            let bucketName = "Audios/";
            let i = 0;
            firebase.storage().ref(`${bucketName}`).child(usuarioId).listAll().then(function (result) {
                if (result.items.length === 0){
                    setTimeout(showMessage, 50);
                }
                result.items.forEach(function (imageRef) {
                    displayMetadata(i, imageRef, bucketName);
                    i++;
                });
            });
        }
    }

    const getUrl1 = () => {
        setLoader(true);
        if (newFormatOption === "Video") {
            let bucketName = "Videos/"+usuarioId;
            let storageRef = firebase.storage().ref(`${bucketName}/${ubicacionId}`);
                storageRef.getDownloadURL().then(url => {
                    url1 = (url);
                    obtainSize(url1, storageRef);
                }).catch(function (error){
                    console.log(error);
                    setMessage(true);
                })
        } else if (newFormatOption === "Imagen") {
            let bucketName = "Imagenes/"+usuarioId;
            let storageRef = firebase.storage().ref(`${bucketName}/${ubicacionId}`);
            storageRef.getDownloadURL().then(url => {
                url1 = (url);
                obtainSize(url1, storageRef);
            }).catch(function (error){
                console.log(error);
                setMessage(true);
            })
        } else if (newFormatOption === "Audio") {
            let bucketName = "Audios/"+usuarioId;
            let storageRef = firebase.storage().ref(`${bucketName}/${ubicacionId}`);
            storageRef.getDownloadURL().then(url => {
                url1 = (url);
                obtainSize(url1, storageRef);
            }).catch(function (error){
                console.log(error);
                setMessage(true);
            })
        }
    }

    const obtainSize = (url1, storageRef) => {
        storageRef.getMetadata().then(size => {
            size1 = (size.size)
            obtainDate(url1, size1, storageRef);
        })
    }

    const obtainDate = (url1, size1, storageRef) => {
        storageRef.getMetadata().then(date => {
            date1 = (date.updated);
            validateMultimedia(url1, size1, date1);
        })
    }

    const validateMultimedia = (url1, size1, date1) => {
        axios.post(process.env.REACT_APP_API_URL + '/multimedia/surveys/validate-multi', {file_name: ubicacionId})
            .then((res) => {
                validate1 = res.data.response;
            })
            .catch(() => {
                validate1 = 10;
            })
            .finally(() => {
                mergeData(url1, size1, date1, validate1);
            });
    }

    const mergeData = (url1,size1, date1, validate1) => {
        array.push({name: ubicacionId, size: size1, url: url1, date: date1.slice(0,10), validate: validate1});
        data=(array);
        setButtonDis(true);
        setInquirieDis(false);
        setData1(JSON.parse(JSON.stringify(array)));
        if(array.length >0){
            setLoader(false);
        }
    }

    const showMessage = () => {
        setUserInvalid(true);
    }

    const displayMetadata = (row, images, bucketName) => {
        images.getDownloadURL().then(function(urlCall){
            url=urlCall;
            displayName(url, row, images, bucketName)
        })
    }

    const displayName = (url, row, images, bucketName) => {
        images.getMetadata().then(function(nameCall){
            name = nameCall.name;
            if (name.includes(ubicacionId)){
                trueName = name;
            }
            displayName1(url, row, images, bucketName, trueName);
        })
    }

    const displayName1= (url, row, images, bucketName, trueName) => {
        if (trueName.length === 0){
            setTextError(true);
            setLoader(false);
        }else {
            displayDate(trueName, url, row, images, bucketName)
        }
    }

    const displayDate = (name, url, row, images, bucketName) => {
        firebase.storage().ref(`${bucketName}`).child(usuarioId+"/"+name).getMetadata().then(dateCall =>
        {
            date = (dateCall.updated);
            displaySize(name, url, date, row, images, bucketName)
        }).catch(function (error){
            console.log(error)
        })
    }

    const displaySize = (name, url, date, row, images, bucketName) => {
        firebase.storage().ref(`${bucketName}`).child(usuarioId+"/"+name).getMetadata().then(sizeCall =>
        {
            size = (sizeCall.size);
            validateMulti(size, url, name, date);
        }).catch(function (error){
            console.log(error)
        })
    }

    const validateMulti = (size, url, name, date) => {
        axios.post(process.env.REACT_APP_API_URL + '/multimedia/surveys/validate-multi', {file_name: name})
            .then((res) => {
                validate = res.data.response;
            })
            .catch(() => {
                validate = 100;
            })
            .finally(() => {
                joinData(size, url, name, date, validate);
            });
    }

    const joinData = (size, url, name, date, validate) => {
        array.push({name: name, size: size, url: url, date: date.slice(0,10), validate: validate});
        data=(array);
        setButtonDis(true);
        setInquirieDis(false);
        setData1(JSON.parse(JSON.stringify(array)));
        if(array.length >0){
            setLoader(false);
        }
    }

    const pageChange = (event) => {
        setSkip(event.page.skip)
        setTake(event.page.take)
    }

    const newInquirie = () => {
        window.location.reload();
    }

    const searchEvent = (e) => {
        setFilter(
            {
                logic: 'or',
                filters: [
                    { field: "name", operator: "contains", value: e.target.value },
                    { field: "size", operator: "contains", value: e.target.value },
                    { field: "url", operator: "contains", value: e.target.value },
                    { field: "date", operator: "contains", value: e.target.value }
                ]
            }
        );
    }

    const closePopUp = () => {
        setMessage(false);
        setLoader(false);
    }

    const invalidUser = () =>{
        setUserInvalid(false);
        window.location.reload();
    }

    const verifyFormat = (e) => {
        if (newFormatOption !== ""){
            verifySearchType(e);
        }else{
            setFormatError(true);
        }
    }

    const verifySearchType = (e) => {
        if (newSearchType !== ""){
            if (newSearchType === "Id"){
                verifySearchText(e);
            }else if (newSearchType === "Nombre"){
                verifySearchText1(e);
            }
        }else{
            setSearchError(true);
        }
    }

    const verifySearchText = (e) => {
        if (ubicacionId !== 0){
            verifyUserId(e);
        }else {
            setSearchTextError(true);
        }
    }

    const verifySearchText1 = (e) => {
        if (ubicacionId !== 0){
            verifyUserId(e);
        }else {
            setSearchTextError1(true);
        }
    }

    const verifyUserId = (e) =>{
        if (usuarioId.length > 1){
            if (newSearchType === "Id"){
                getUrl(e);
            }else if (newSearchType === "Nombre"){
                getUrl1()
            }
        }else{
            setUserError(true);
        }
    }

    const closeSnack = () => {
        setFormatError(false);
        setUserError(false);
        setSearchError(false);
        setDateInfo(false);
        setSearchTextError(false);
        setSearchTextError1(false);
        setTextError(false);
    }

    const loadButton = (props) => {
        if (props.dataItem.validate === 1){
            return (
                <td>

                </td>
            )
        }else {
            return (
                <td>
                    <div style={{textAlign: 'center', alignContent: 'center'}}>
                        <LoadURL props={{
                            userId: userId,
                            name: props.dataItem.name,
                            size: props.dataItem.size,
                            url: props.dataItem.url
                        }}
                        />
                    </div>
                </td>
            )
        }
    }

    return(
        <td>
            <h3 style={{textAlign: 'center', color: '#FF995B'}}>Consultas Firebase</h3>
            <div>
                <Dialog
                    open={formatError}
                    onClose={closeSnack}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"P R E C A U C I O N"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Debe seleccionar un formato
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeSnack} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={userError}
                    onClose={closeSnack}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"P R E C A U C I O N"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Debe escribir un usuario
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeSnack} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={searchError}
                    onClose={closeSnack}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"P R E C A U C I O N"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Debe seleccionar el tipo de búsqueda
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeSnack} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={searchTextError}
                    onClose={closeSnack}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"P R E C A U C I O N"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Debe escribir un ID de ubicación tarea
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeSnack} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={searchTextError1}
                    onClose={closeSnack}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"P R E C A U C I O N"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Debe escribir el NOMBRE del registro que desea buscar
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeSnack} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={textError}
                    onClose={closeSnack}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"PROCESO TERMINADO"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Se muestran coincidencias encontradas.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeSnack} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={message}
                    onClose={closePopUp}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"E R R O R"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Id de usuario o nombre de archivo inexistente(s).

                            Por favor, verifique que esté escrito correctamente.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closePopUp} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={userInvalid}
                    onClose={invalidUser}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"E R R O R"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            El usuario que ingresó no existe, favor de ingresar otro
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={invalidUser} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <div style={{alignItems: "center", textAlign: "center"}}>
                    <FormControl style={{width: "15%", margin: "3px", marginLeft: "10px"}}>
                        <Autocomplete
                            // id="size-small-standard"
                            options={formatOptions}
                            // size={"small"}
                            onChange={setNewFormatOption}
                            getOptionLabel={(option) => option.format}
                            renderInput={(params) => <TextField value={params} {...params} label={"Formato"} variant="standard" />}
                        />
                    </FormControl>
                    <FormControl style={{width: "15%", margin: "3px", marginLeft: "10px"}}>
                        <Autocomplete
                            // id="size-small-standard"
                            options={formatOptions1}
                            // size={"small"}
                            onChange={setNewFormatOption1}
                            getOptionLabel={(option) => option.search}
                            renderInput={(params) => <TextField value={params} {...params} label={"Tipo de Búsqueda"} variant="standard" />}
                        />
                    </FormControl>
                    <FormControl style={{width: "15%", marginLeft: "20px"}}>
                        <TextField
                            type={"number"}
                            label={"Id Usuario"}
                            inputProps={{className: 'digitsOnly'}}
                            placeholder={"Id Usuario"}
                            onChange={newTextField}
                            error={errorMessage1}
                            helperText="Longitud mínima: 1"
                        />
                    </FormControl>
                    <FormControl style={{width: "15%", marginLeft: "20px", margin: "15px"}}>
                        <TextField
                            placeholder={placeholder}
                            onChange={newTextField1}
                            error={errorMessage2}
                            helperText={placeholder}
                        />
                    </FormControl>
                </div>
                <br/>
                <div style={{alignItems:"center", textAlign: "center"}}>
                    <button onClick={verifyFormat} disabled={buttonDis}>METADATA</button> <rb/>
                    <button onClick={newInquirie} disabled={inquirieDis}>NUEVA CONSULTA</button>
                </div>
            </div>
            <div>
                <br/>
                {loader && <Linear/>}
                <LocalizationProvider language={"es-ES"}>
                    <IntlProvider locale={"es"}>
                        <Input
                            style={{width: '100%'}}
                            className={'full-space'}
                            placeholder={'Buscar'}
                            onChange={searchEvent}
                        />
                        <br/>
                        <br/>
                        <div>
                            <Grid
                                style={{
                                    height: '400px',
                                    fontSize: '12px',
                                    fontFamily: 'Arial',
                                    textAlignLast: 'center',
                                    textAlign: 'center',
                                    marginLeft: '20px',
                                    marginRight: '20px'
                                }}
                                data={filterBy(data1, filter).slice(skip,take + skip)}
                                skip={skip}
                                take={take}
                                total={data1.length}
                                pageable={{buttonCount: 4, pageSizes: true}}
                                onPageChange={pageChange}
                                resizable={true}
                            >
                                <GridColumn title={'Nombre'} field={'name'} filterable={false} width={'auto'}/>
                                <GridColumn title={'Tamaño'} field={'size'} width={'auto'}/>
                                <GridColumn title={'URL'} field={'url'} filterable={false} width={'auto'}/>
                                <GridColumn title={'Fecha'} field={'date'} filterable={false} width={'auto'}/>
                                <GridColumn title={'Cargar'} cell={loadButton} width={'auto'}/>
                            </Grid>
                            <Back/>
                        </div>
                    </IntlProvider>
                </LocalizationProvider>
            </div>
        </td>
    )
}

export default InquiriesV2;
