import React, {useEffect} from 'react';
import {loadMessages, LocalizationProvider} from '@progress/kendo-react-intl';
import esMessages from '../es.json';
import {Autocomplete} from "@material-ui/lab";
import {Grid, TextField} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Spinner from "./Spinner";
import axios from "axios";
import {GridColumn} from "@progress/kendo-react-grid";
import IntlProvider from "@progress/kendo-react-intl/dist/es/Intl/IntlProvider";
loadMessages(esMessages, 'es-ES');

let logType = "";
let select1 = [];
let option = "";
let newUserId = 0;
const options = [{title: "AllLogs"}, {title: "iOSLogs"}, {title: "userLogs"}];
const users = [{userName: "12345"}, {userName: "67890"}, {userName: "13579"}];
let today = new Date();
let actual = today.getFullYear() + '-'+( '0' + (today.getUTCMonth()+1)).slice(-2).toString()+ '-' + ('0' + today.getDate()).slice(-2).toString();
let actual1 = actual;
let fin1 = actual;
let datesFire = [];
let dateMilliseconds = [];
let dateGoods = [];
let datesSeconds = []
let allLogs1 = [];
let dateP = "";

function Logs(props) {

    let userId = atob(props.match.params.userId);
    const [loader, setLoader] = React.useState(true);
    const [disabledLogType, setDisabledLogType] = React.useState(true);
    const [disabledOptions, setDisableOptions] = React.useState(false);
    const [disabledUserId, setDisabledUserId] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [skip, setSkip] = React.useState(0);
    const [take, setTake] = React.useState(5);
    const [newUserId1, setNewUserId1] = React.useState(0);
    const [errorMessage, setErrorMessage] = React.useState(false);

    useEffect(() => {
        axios.post('http://127.0.0.1:5000/adm/logs/inquiries/get-logs', {user_id: userId})
            .then((res) =>{
                select1=(res.data.response.response);
                console.log(select1);
            })
            .catch(() =>{
                select1=([]);
            })
            .finally(() => {
                setLoader(false);
            })
    },[userId]);

    const setLogType = (e) =>{
        logType = (e.target.innerText);
        setDisabledLogType(true);
        setDisabledUserId(false);
        console.log(logType);
    }

    const setOption = (e) => {
        option = (e.target.innerText);
        setDisableOptions(true);
        setDisabledLogType(false);
        console.log(option)
    }

    const pageChange = (event) => {
        setSkip(event.page.skip)
        setTake(event.page.take)
    }

    const newDate = (e) => {
        actual1=(e.target.value)
    }

    const newDate1 = (e) => {
        fin1=(e.target.value)
    }

    const newTextField = (e) => {
        setNewUserId1(e.target.value)
        newUserId = (e.target.value)
        console.log(newUserId.toString());
        quantityError(newUserId);
    }

    const quantityError = (newUserId) => {
        if (newUserId.length < 1){
            setErrorMessage(true);
        }else{
            setErrorMessage(false);
        }
    }

    const getDatesFire = () => {
        axios.post('http://127.0.0.1:5000/adm/logs/inquiries/get-logs-dates', {log_Type: logType, id_user: newUserId.toString()})
            .then((res) => {
                datesFire = res.data.response.response;
            })
            .catch((e) => {
                datesFire = [];
                console.log(e)
            })
            .finally(() => {
                convertToMilliseconds();
            });
    }

    const convertToMilliseconds = () => {
        for(let i = 0; i < datesFire.length; i++){
            dateMilliseconds.push(Date.parse(datesFire[i]))
        }
        for (let j = 0; j < dateMilliseconds.length; j++){
            if(dateMilliseconds[j] > Date.parse(actual1) && dateMilliseconds[j] < Date.parse(fin1)){
                dateGoods.push(dateMilliseconds[j])
                datesSeconds.push(datesFire[j])
            }
        }
        getAllLogs1();
    }

    const getAllLogs1 = () =>{
        for (let p = 0; p < datesSeconds.length; p++){
            dateP = datesSeconds[p];
            obtainAllLogs(dateP);
        }
    }

    const obtainAllLogs = (dateP) => {
        axios.post('http://127.0.0.1:5000/adm/logs/inquiries/get-all-logs-1', {log_Type: logType, user_id: newUserId.toString(), date: dateP})
            .then((res) => {
                allLogs1.push(res.data.response.response);
            })
            .catch(() => {
                allLogs1 = [];
                console.log("error");
            })
            .finally(() => {
                console.log(JSON.stringify(allLogs1));
                console.log(Object.assign({}, allLogs1));
            });
    }

    const newInquirie = () => {
        window.location.reload();
    }

    return(
        <td>
            <div style={{width: "1000px", textAlign:"center", alignItems: "center"}}>
                <h3 style={{textAlign: 'center', color: '#FF995B'}}>Logs Firebase</h3>
                <section>
                {loader && <Spinner/>}
                <FormControl style={{width: "10%"}}>
                    <Autocomplete
                        id="combo-box"
                        options={options}
                        size={"small"}
                        onChange={setOption}
                        disabled={disabledOptions}
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => <TextField value={params} {...params} label={"LogType"} variant="standard" />}
                    />
                </FormControl>
                <FormControl style={{width: "20%", marginLeft:"10px"}}>
                    <Autocomplete
                        id="combo-box"
                        options={select1}
                        size={"small"}
                        onChange={setLogType}
                        disabled={disabledLogType}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => <TextField value={params} {...params} label={"LogName"} variant="standard" />}
                    />
                </FormControl>
                    <FormControl style={{width: "15%", marginLeft: "16px", margin: "-3px"}}>
                        <TextField
                            type={"number"}
                            label={"Id Usuario"}
                            inputProps={{className: 'digitsOnly'}}
                            placeholder={"Id Usuario"}
                            onChange={newTextField}
                            error={errorMessage}
                            helperText="Longitud mínima: 1"
                        />
                    </FormControl>
                <FormControl style={{width: "15%", margin: "-3px", marginLeft:"15px"}}>
                    <TextField
                        id={"initialDate"}
                        label={"Fecha de inicio"}
                        type={"date"}
                        defaultValue={actual1}
                        onChange={newDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormControl>
                    <FormControl style={{width: "15%", margin: "-3px",marginLeft:"15px"}}>
                        <TextField
                            id={"finalDate"}
                            label={"Fecha de fin"}
                            type={"date"}
                            defaultValue={fin1}
                            onChange={newDate1}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                    <br/>
                    <br/>
                    <button onClick={getDatesFire}>Consultar</button> <rb/>
                    <button onClick={newInquirie}>Nueva Consulta</button>
                </section>
            </div>
            <div>
                <LocalizationProvider language="es-ES">
                    <IntlProvider locale="es" >
                        <div style={{textAlign: 'center'}}>
                            <Grid
                                style={{
                                    height: '480px',
                                    width: '100%',
                                    fontSize: '12px',
                                    fontFamily: 'Arial'
                                }}
                                data={(data).slice(skip,take + skip)}
                                skip={skip}
                                take={take}
                                total={data.length}
                                pageable={{buttonCount: 4, pageSizes: true}}
                                onPageChange={pageChange}
                                resizable
                            >
                                <GridColumn field={'SO'} title={"SO"} width={"auto"}/>
                                <GridColumn field={'Interfaz'} title={"Interfaz"} width={"auto"}/>
                                <GridColumn field={'Version'} title={"Version"} width={"auto"}/>
                            </Grid>
                        </div>
                    </IntlProvider>
                </LocalizationProvider>
            </div>
        </td>
    )
}

export default Logs;