import React from "react";
import esMessages from '../es.json'
import firebase from "../components/firebase";
import {IntlProvider, loadMessages, LocalizationProvider} from "@progress/kendo-react-intl";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Input, Snackbar,
    TextField
} from "@material-ui/core";
import {Alert, Autocomplete} from "@material-ui/lab";
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import Back from "./Back";
import {filterBy} from "@progress/kendo-data-query";
import Button from "@progress/kendo-react-buttons/dist/es/Button";
import Linear from "./Loader/CircularLoader";

loadMessages(esMessages, 'es-ES')

let newFormatOption = "";
let usuarioId = 0;
let name = [];
let url = [];
let size = [];
let goodDates = [];
let date = [];
let data = [];
let date1 = [];
let date1Length = 0;
let placeholder = [];
let array = [];
let today = new Date();
let actual = today.getFullYear() + '-'+( '0' + (today.getUTCMonth()+1)).slice(-2).toString()+ '-' + ('0' + today.getDate()).slice(-2).toString();
let actual1 = actual;
let fin = today.getFullYear() + '-'+( '0' + (today.getUTCMonth()+1)).slice(-2).toString()+ '-' + ('0' + today.getDate()).slice(-2).toString();
let fin1 = fin;
let outDate = [];


function Inquiries(props){

    let userId = props.props.userId;
    const [loader, setLoader] = React.useState(false);
    const [message, setMessage] = React.useState(false);
    const [data1, setData1] = React.useState((array));
    const [buttonDis, setButtonDis] = React.useState(false);
    const [inquirieDis, setInquirieDis] = React.useState(true);
    const [errorMessage1, setErrorMessage1] = React.useState(false);
    const [formatError, setFormatError] = React.useState(false);
    const [userError, setUserError] = React.useState(false);
    const [dateError, setDateError] = React.useState(false);
    const [dateInfo, setDateInfo] = React.useState(false);
    const [userInvalid, setUserInvalid] = React.useState(false);
    const [idUsuario, setIdUsuario] = React.useState(0);
    const formatOptions = [{format: "Audio"}, {format: "Video"}, {format: "Imagen"}]
    const [skip, setSkip] = React.useState(0);
    const [take, setTake] = React.useState(20);
    const [filter, setFilter] = React.useState({
        logic: "or",
        filters: [
            { field: "name", operator: "contains", value: "" },
            { field: "size", operator: "contains", value: "" },
            { field: "url", operator: "contains", value: "" },
            { field: "date", operator: "contains", value: "" }
        ]
    })

    const setNewFormatOption = (e) => {
        newFormatOption = (e.target.innerText);
        console.log(newFormatOption);
    }

    const newTextField = (e) => {
        setIdUsuario(e.target.value)
        usuarioId = (e.target.value)
        quantityError(usuarioId);
    }

    const quantityError = (usuarioId) => {
        if (usuarioId.length < 1){
            setErrorMessage1(true);
        }else{
            setErrorMessage1(false);
        }
    }

    // async function pageTokenExample(){
    //     // Create a reference under which you want to list
    //     let bucketName = "Videos/";
    //     let listRef = firebase.storage().ref(`${bucketName}`).child(usuarioId);
    //     let firstPage = await listRef.list({ maxResults: 1000})
    //     .then( firstPage => {
    //         for(let i = 0; i <firstPage.items.length; i++){
    //         placeholder.push(firstPage.items[i].name);
    //         }
    //         displayMetadata(placeholder);
    //     })
    //     // Use the result.
    //     // processItems(firstPage.items)
    //     // processPrefixes(firstPage.prefixes)
    //     // Fetch the second page if there are more elements.
    //     // if (firstPage.nextPageToken) {
    //     //     let secondPage = await listRef.list({
    //     //         maxResults: 100,
    //     //         pageToken: firstPage.nextPageToken,
    //     //     });
    //     //     // processItems(secondPage.items)
    //     //     // processPrefixes(secondPage.prefixes)
    //     //     console.log(secondPage.items);
    //     //     return secondPage.items;
    //     // }
    // }

    const getUrl = () => {
        setLoader(true);
                if (newFormatOption === "Video") {
                    let bucketName = "Videos/";
                    let i = 0;
                    firebase.storage().ref(`${bucketName}`).child(usuarioId).listAll().then(function (result) {
                        if (result.items.length === 0){
                            setTimeout(showMessage, 5000);
                            }
                        result.items.forEach(function (imageRef) {
                            displayMetadata(i, imageRef, bucketName);
                            i++;
                            // setMessage(true);
                        });

                    });
                    placeholder = "Id Ubicacion Tarea";
                } else if (newFormatOption === "Imagen") {
                    let bucketName = "Imagenes/";
                    let i = 0;
                    firebase.storage().ref(`${bucketName}`).child(usuarioId).listAll().then(function (result) {
                        if (result.items.length === 0){
                            setTimeout(showMessage, 5000);
                        }
                        result.items.forEach(function (imageRef) {
                            displayMetadata(i, imageRef, bucketName);
                            // setMessage(true);
                            i++;
                        });
                    });
                    placeholder = "Id Ubicacion Tarea";
                } else if (newFormatOption === "Audio") {
                    let bucketName = "Audios/";
                    let i = 0;
                    firebase.storage().ref(`${bucketName}`).child(usuarioId).listAll().then(function (result) {
                        if (result.items.length === 0){
                            setTimeout(showMessage, 5000);
                        }
                        result.items.forEach(function (imageRef) {
                            displayMetadata(i, imageRef, bucketName);
                            // setMessage(true);
                            i++;
                        });
                    });
                    placeholder = "Id Ubicacion Tarea";
                }
    }

    const showMessage = () => {
        setUserInvalid(true);
    }

    // const displayMetadata = (placeholder) => {
    //     let bucketName = "Videos/";
    //     for (let i = 0; i < placeholder.length; i++){
    //         firebase.storage().ref(`${bucketName}`).child(usuarioId+"/"+placeholder[i]).getDownloadURL().then(urlCall =>
    //             {
    //                 url.push(urlCall);
    //             }).catch(function (error){
    //                 console.log(error)
    //             })
    //     }
    //     displayDate(placeholder);
    // }
    //
    // const displayDate = (placeholder) => {
    //     let bucketName = "Videos/";
    //     for (let i = 0; i < placeholder.length; i++){
    //         firebase.storage().ref(`${bucketName}`).child(usuarioId+"/"+placeholder[i]).getMetadata().then(dateCall =>
    //         {
    //             date.push(dateCall.updated.slice(0,10));
    //             if (Date.parse(date[i]) > Date.parse(actual1) && Date.parse(date[i]) < Date.parse(fin1)){
    //                 goodDates.push(date[i]);
    //                 console.log(date[i]);
    //                 console.log("SI");
    //             }else{
    //                 outDate.push(date[i]);
    //                 console.log(date[i]);
    //                 console.log("NO");
    //             }
    //             // addDate(date);
    //             // readLength(date)
    //         }).catch(function (error){
    //             console.log(error)
    //         })
    //     }
    //     // console.log(date1.length);
    //     // for2(placeholder, date);
    //     displayName(placeholder);
    // }

    // const addDate = (date) =>{
    //     date1 = date;
    //     return date1;
    // }
    //
    // const readLength = (date) => {
    //     date1Length = date.length;
    //     return date1Length;
    // }
    //
    // const for2 = (placeholder, date1) => {
    //     console.log(date1);
    //     console.log(date1.length);
    //     for (let j = 0; j < date1.length; j++){
    //         console.log("ENTRA DATE 2 FOR");
    //         if (Date.parse(date1[j]) > Date.parse(actual1) && Date.parse(date1[j]) < Date.parse(fin1)){
    //             console.log(Date.parse(date1[j]));
    //             console.log(Date.parse(actual1));
    //             goodDates.push(date1[j])
    //         }else{
    //             outDate.push(date1[j]);
    //             return outDate;
    //         }
    //     }
    //     displayName(placeholder);
    // }

    // const displayName = (placeholder) => {
    //     let bucketName = "Videos/";
    //     for (let i = 0; i < placeholder.length; i++){
    //         firebase.storage().ref(`${bucketName}`).child(usuarioId+"/"+placeholder[i]).getMetadata().then(nameCall =>
    //         {
    //             name.push(nameCall.name);
    //         }).catch(function (error){
    //             console.log(error)
    //         })
    //     }
    //     displaySize(placeholder);
    // }
    //
    // const displaySize = (placeholder) => {
    //     let bucketName = "Videos/";
    //     for (let i = 0; i < placeholder.length; i++){
    //         firebase.storage().ref(`${bucketName}`).child(usuarioId+"/"+placeholder[i]).getMetadata().then(sizeCall =>
    //         {
    //             size.push(sizeCall.size);
    //
    //         }).catch(function (error){
    //             console.log(error)
    //         })
    //     }
    //     joinData();
    // }
    //
    // const joinData = () => {
    //     console.log(url);
    //     console.log(date);
    //     console.log(goodDates);
    //     console.log(name);
    //     console.log(size);
    //     console.log(outDate);
    // }

    const displayMetadata = (row, images, bucketName) => {
        images.getDownloadURL().then(function(urlCall){
            url=urlCall;
            displayName(url, row, images, bucketName);
        })
    }

    const displaySize = (name, url, row, images, bucketName) => {
        firebase.storage().ref(`${bucketName}`).child(usuarioId+"/"+name.name).getMetadata().then(sizeCall =>
        {
            size = (sizeCall.size);
            displayDate(name, url, row, images, bucketName);
        }).catch(function (error){
            console.log(error)
        })
    }

    const displayDate = (name, url, row, images, bucketName) => {
        firebase.storage().ref(`${bucketName}`).child(usuarioId+"/"+name.name).getMetadata().then(dateCall =>
        {
            date = (dateCall.updated);
            joinData(name, url, size, date)
            // if (Date.parse(date) > Date.parse(actual1) && Date.parse(date) < Date.parse(fin1)){
            //     joinData(name, url, size, date);
            // }else{
            //     outDate.push(date);
            //     return outDate;
            // }
        }).catch(function (error){
            console.log(error)
        })
    }

    const displayName = (url, row, images, bucketName) => {
        images.getMetadata().then(function(nameCall){
            name = nameCall;
            displaySize(name, url, row, images, bucketName)
        })
    }

    const joinData = (name, url,size, date) => {
        array.push({name: name.name, size: size, url: url, date: date.slice(0,10)});
        data=(array);
        setButtonDis(true);
        setInquirieDis(false);
        setData1(JSON.parse(JSON.stringify(array)));
        if(array.length >0){
            setLoader(false);
        }
    }

    const pageChange = (event) => {
        setSkip(event.page.skip)
        setTake(event.page.take)
    }

    const newInquirie = () => {
        window.location.reload();
    }

    const searchEvent = (e) => {
        setFilter(
            {
                logic: 'or',
                filters: [
                    { field: "name", operator: "contains", value: e.target.value },
                    { field: "size", operator: "contains", value: e.target.value },
                    { field: "url", operator: "contains", value: e.target.value },
                    { field: "date", operator: "contains", value: e.target.value }
                ]
            }
        );
    }

    // const newDate = (e) => {
    //     actual1 = (e.target.value)
    //     console.log(actual1);
    // }
    //
    // const newDate1 = (e) => {
    //     fin1=(e.target.value)
    // }

    const closePopUp = () => {
        setMessage(false);
        setLoader(false);
    }

    const invalidUser = () =>{
        setUserInvalid(false);
        window.location.reload();
    }

    const verifyFormat = (e) => {
        if (newFormatOption !== ""){
            verifyUserId(e);
        }else{
            setFormatError(true);
        }
    }

    const verifyUserId = (e) =>{
        if (usuarioId.length > 1){
            getUrl(e);
        }else{
            setUserError(true);
        }
    }

    // const verifyInitialDate = (e) => {
    //     if (actual1 !== actual){
    //         verifyFinalDate()
    //     }else {
    //         setDateError(true);
    //     }
    // }
    //
    // const verifyFinalDate = (e) => {
    //     if (fin !== fin){
    //         // pageTokenExample();
    //         getUrl(e);
    //     }else {
    //         // pageTokenExample();
    //         getUrl(e);
    //     }
    // }

    const closeSnack = () => {
        setFormatError(false);
        setUserError(false);
        setDateError(false);
        setDateInfo(false);
    }

    return(
        <td>
            <h3 style={{textAlign: 'center', color: '#FF995B'}}>Consultas Firebase</h3>
            <div>
                <Snackbar open={dateInfo} autoHideDuration={5000} onClose={closeSnack} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                    <Alert onClose={closeSnack} severity="info" variant={"filled"}>
                        Fecha de fin: Fecha actual
                    </Alert>
                </Snackbar>
                <Snackbar open={formatError} autoHideDuration={5000} onClose={closeSnack} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                    <Alert onClose={closeSnack} severity="error" variant={"filled"}>
                        Debe seleccionar un formato
                    </Alert>
                </Snackbar>
                <Snackbar open={userError} autoHideDuration={5000} onClose={closeSnack} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                    <Alert onClose={closeSnack} severity="error" variant={"filled"}>
                        Debe escribir un usuario
                    </Alert>
                </Snackbar>
                <Snackbar open={dateError} autoHideDuration={5000} onClose={closeSnack} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                    <Alert onClose={closeSnack} severity="error" variant={"filled"}>
                        Debe seleccionar una fecha
                    </Alert>
                </Snackbar>
                <Dialog
                    open={message}
                    onClose={closePopUp}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"DATOS CARGADOS"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Proceso terminado
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closePopUp} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={userInvalid}
                    onClose={invalidUser}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"E R R O R"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            El usuario que ingresó no existe, favor de ingresar otro
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={invalidUser} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            <div style={{alignItems: "center", textAlign: "center"}}>
            <FormControl style={{width: "15%", margin: "3px", marginLeft: "10px"}}>
                <Autocomplete
                    id="size-small-standard"
                    options={formatOptions}
                    size={"small"}
                    onChange={setNewFormatOption}
                    getOptionLabel={(option) => option.format}
                    renderInput={(params) => <TextField value={params} {...params} label={"Formato"} variant="standard" />}
                />
            </FormControl>
            <FormControl style={{width: "15%", marginLeft: "20px"}}>
                <TextField
                    type={"number"}
                    label={"Id Usuario"}
                    inputProps={{className: 'digitsOnly'}}
                    placeholder={"Id Usuario"}
                    onChange={newTextField}
                    error={errorMessage1}
                    helperText="Longitud mínima: 1"
                />
            </FormControl>
            {/*<FormControl style={{width: "15%", marginLeft: "20px"}}>*/}
            {/*        <TextField*/}
            {/*            id={"date"}*/}
            {/*            label={"Fecha de inicio"}*/}
            {/*            type={"date"}*/}
            {/*            defaultValue={actual1}*/}
            {/*            onChange={newDate}*/}
            {/*            InputLabelProps={{*/}
            {/*                shrink: true,*/}
            {/*            }}*/}
            {/*        />*/}
            {/*</FormControl>*/}
            {/*<FormControl style={{width: "15%", marginLeft: "20px"}}>*/}
            {/*        <TextField*/}
            {/*            id={"date"}*/}
            {/*            label={"Fecha de fin"}*/}
            {/*            type={"date"}*/}
            {/*            defaultValue={fin1}*/}
            {/*            onChange={newDate1}*/}
            {/*            InputLabelProps={{*/}
            {/*                shrink: true,*/}
            {/*            }}*/}
            {/*        />*/}
            {/*</FormControl>*/}
            </div>
                <br/>
                <div style={{alignItems:"center", textAlign: "center"}}>
                <button onClick={verifyFormat} disabled={buttonDis}>METADATA</button> <rb/>
                <button onClick={newInquirie} disabled={inquirieDis}>NUEVA CONSULTA</button>
                </div>
            </div>
            <div>
                <br/>
                {loader && <Linear/>}
                <LocalizationProvider language={"es-ES"}>
                    <IntlProvider locale={"es"}>
                        <Input
                            style={{width: '100%'}}
                            className={'full-space'}
                            placeholder={'Buscar'}
                            onChange={searchEvent}
                        />
                        <br/>
                        <br/>
                        <div>
                            <Grid
                                style={{
                                    height: '400px',
                                    fontSize: '12px',
                                    fontFamily: 'Arial',
                                    textAlignLast: 'center',
                                    textAlign: 'center'
                                }}
                                data={filterBy(data1, filter).slice(skip,take + skip)}
                                skip={skip}
                                take={take}
                                total={data1.length}
                                pageable={{buttonCount: 4, pageSizes: true}}
                                onPageChange={pageChange}
                                resizable={true}
                            >
                                <GridColumn title={'Nombre'} field={'name'} filterable={false} width={'auto'}/>
                                <GridColumn title={'Tamaño'} field={'size'} width={'auto'}/>
                                <GridColumn title={'URL'} field={'url'} filterable={false} width={'auto'}/>
                                <GridColumn title={'Fecha'} field={'date'} filterable={false} width={'auto'}/>
                            </Grid>
                            <Back/>
                        </div>
                    </IntlProvider>
                </LocalizationProvider>
            </div>
        </td>
    )
}

export default Inquiries;